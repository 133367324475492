import { createToast } from 'mosha-vue-toastify';

function SEARCH(data,kword){
    // console.log("search data",data)
    let newData = []
    // x = row
    data.filter(x=>
        { 
           Object.keys(x).forEach((key)=> { 
               if(x[key] != null)
               {
                    const dt = x[key].toString();
                    if(dt.toLowerCase().includes(kword.toLowerCase()))
                    {
                        if(!newData.includes(x))
                        { 
                            newData.push(x);
                        }
                    }
               }
           })  
        })
    return newData.length===0 ? 
    (createToast('No related record be found',{transition: 'zoom', type: 'info', showIcon: 'true', hideProgressBar: 'true',}),newData='') : newData
}

function dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        var result = (new Date(a[property]) < new Date(b[property])) ? -1 : (new Date(a[property]) > new Date(b[property])) ? 1 : 0;
        return result * sortOrder;
    }
}


const Fn = {
    SEARCH,
    dynamicSort  
};
export default Fn;