<template>
  <div class="my-3" id="search">
    <div class="input-group">
      <input type="text" class="form-control" id="searchTerm" v-model="searchTerm" placeholder="Search key word" aria-label="searchTerm"/>
      <button type="button" class="input-group-text" @click="reset"> Reset </button>
    </div>
  </div>
  
  <DataTable
    :is-slot-mode="true"
    :is-loading="table.isLoading"
    :is-re-search="table.isReSearch"
    :columns="table.columns"
    :rows="table.rows"
    :total="table.totalRecordCount"
    :sortable="table.sortable"
    @do-search="doSearch"
    @is-finished="table.isLoading = flase">
    
      <template #provenanceId="data" >
        <router-link 
        class="text-decoration-none"
        :to="{
          name:'Provenance',
          query:{ provenanceId: data.value.provenanceId }
        }" 
        data-bs-toggle="tooltip" 
        :title='data.value.provenanceId'>
          <div class="text-truncate" style="max-width:200px;">
            <i class="bi bi-zoom-in" alt="Edit"></i>&emsp;
          {{ data.value.provenanceId}}
          </div>
        </router-link>
      </template>
      <template #CreatedDate="data"> {{ data.value.CreatedDate}} </template>
      <template #LastUpdate="data"> {{ data.value.LastUpdate}} </template>
    </DataTable>
</template>

<script> 
import DataTable from '@/components/DataTable.vue';
import { computed, reactive, ref } from "vue";
import DataServices from '../../services/data-service';
import Fn from '@/components/function.js';

export default {
  name: "ProvenanceList",
  components: { DataTable },
  data(){
    return{
      data:[],
    }
  },
  setup(){
    const table = reactive({
      isLoading: true,
      isReSearch: false,
      columns: [ 
        {
          label: "provenanceId",
          field: "provenanceId",
          isKey: true,
          sortable: true,
          width:"20%"
        },
        {
          label: "No of block(s)",
          field: "Noblock",
          sortable: true,
          width:"20px"
        },
        {
          label: "Created date",
          field: "CreatedDate",
          sortable: true,
        },
        {
          label: "Last update",
          field: "LastUpdate",
          sortable: true,
        },
      ],
      rows:[],
      totalRecordCount: computed(() => {
        return table.rows.length;
      }),
        sortable: {
          order: "id",
          sort: "asc",
        },
      });

    const doSearch = (offset, limit, order, sort) => {
      // console.log("doSearch",offset, limit, order, sort)
      table.isLoading = true;
      setTimeout(() => {
        table.isReSearch = offset == undefined ? true : false;
        if (offset >= 10 || limit >= 20) {
          limit = 20;
        }
        if (sort == "asc") {
          table.rows.sort(function compare(a,b){
            if (typeof a[order] === "string"){
              if (a[order].toUpperCase() < b[order].toUpperCase()) { return -1; }
              if (a[order] > b[order]) { return 1; }
              return 0;
            } else {
              return (a[order] - b[order])
            }
          })
        } else {
          table.rows.reverse(function compare(a,b){
            if (typeof a[order] === "string"){
              if (a[order].toUpperCase() < b[order].toUpperCase()) { return -1; }
              if (a[order] > b[order]) { return 1; }
              return 0;
            } else {
              return (a[order] - b[order])
            }
          })
        }
        table.sortable.order = order;
        table.sortable.sort = sort;
      },600);
    };

    const searchTerm = ref('')

    return { table, doSearch, searchTerm}
  },
  methods: {
    async retrieveData() {
      this.data = await DataServices.GetData();
      this.data = this.data.map((row)=>{ 
        return {
          ...row,
          Noblock: "",
          CreatedDate: "",
          LastUpdate: "",
        }
      })
      
      this.data.forEach(async (row)=>{
        row.Noblock = row.blocks.length
        row.blocks.forEach((block)=>{
          block.createdDate = new Date(block.createdDate)
        });
        row.CreatedDate = new Date(await Math.min.apply(Math,row.blocks.map(function(o){return o.createdDate}))).toDateString()
        row.LastUpdate = new Date(await Math.max.apply(Math,row.blocks.map(function(p){return p.createdDate}))).toDateString()
      })
      this.table.rows = this.data
      this.table.isLoading = false
    },
    async searchResult(searchTerm) {
      this.table.rows = await Fn.SEARCH(this.data,searchTerm);
    },
    reset (){
      this.searchTerm = '';
      this.retrieveData();
    }
  },
  watch:{
    searchTerm(newSearchTerm){
      this.searchResult(newSearchTerm)
    }

  },
  mounted(){
    this.retrieveData()
  }
};
</script>